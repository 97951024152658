// GreyBackgroundElement.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './sensingPageMain.css'
import './sensingNew.css'

import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import DownloadingIcon from '@mui/icons-material/Downloading';
import Stack from '@mui/material/Stack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import Forward5Icon from '@mui/icons-material/Forward5';
import NotificationsPanel from '../../layoutElements/notification';
import LightModeIcon from '@mui/icons-material/LightMode';
import MapViewMulti from '../../components/leaFlatFieldMapMulti';
import { Link, useParams } from 'react-router-dom'
import { useFetchFieldCoordinatesMulti } from '../../hooks/fetch_FieldCoordinates_Multi';
import { useToken } from '../../hooks/useToken';
import { useFarmData } from '../../hooks/fetch_apiFarm';
import { useFarmDataList } from '../../hooks/fetch_apiFarmList';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { polygon } from 'leaflet';
import { Box, CircularProgress } from '@mui/material';
import { useDeviceData } from '../../services/useDeviceData';
import MapWS from './mapSensingUp';
import { Line } from "react-chartjs-2";
import TestChart from './testChart';

import LineChartHum from './charts/sensingLineChartHum';
import LineChartWind from './charts/sensingLineChartWind';
import LineChartRain from './charts//sensingLineChartRain';
import LineChartUV from './charts/sensingLineChartUV';
import LineChartLight from './charts/sensingLineChartLight';
import LineChartPres from './charts/sensingLineChartPres';
import LineChartAR from './charts/sensingLineChartAR';
import LineChart from './charts/sensingLineCharts';

import AirIcon from '@mui/icons-material/Air';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloudQueueOutlinedIcon from '@mui/icons-material/CloudQueueOutlined';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';

import { useAuth } from "../../hooks/useAuth";

import { useWeatherStationData } from '../../hooks/fetch_apiDevices';
import RainfallBarChart from './rainfallGraph';
import * as XLSX from 'xlsx';
import { styled, alpha } from '@mui/material/styles';
import NotSensing from './notSensing';

const style = {
    backgroundColor: 'white', // This sets the background color to grey
    padding: '20px', // Add some padding for content inside
    marginLeft: '1%', // Overrides the left padding to be 5% of the parent's width
    margin: '10px 1%', // Add some vertical margin
    borderRadius: '5px', // Optional: rounds the corners of the element

};


const SensingPageMain = ({ children }) => {


    const shouldShowPumpOverlay = false;
    const token = useToken()
    //const id   = 9// useParams();
    //console.log("id", id)   
    console.log("token", token)
    const { data: dataList, loading: loadingList, error: errorList } = useFarmDataList(token);
    const id = dataList && dataList.length > 0 ? dataList[0].id : null;


    console.log("farm data list", dataList)
    //const { data, loading, error } = useFarmData(token, id); 
    const [selectedFarm, setSelectedFarm] = useState('');
    const [selectedFarmId, setSelectedFarmId] = useState(null);
    useEffect(() => {
        if (dataList && dataList.length > 0) {
            setSelectedFarm(dataList[0].name); // Default to first farm name
            setSelectedFarmId(dataList[0].id);  // Default to first farm ID
        }
    }, [dataList]);
    const { data, loading, error } = useFarmData(token, selectedFarmId);
    console.log("farm data test", data)
    const handleChange = (event) => {
        const farmName = event.target.value;
        setSelectedFarm(farmName);

        // Find the selected farm object and update the selectedFarmId
        const selectedFarmData = dataList.find(farm => farm.name === farmName);
        if (selectedFarmData) {
            setSelectedFarmId(selectedFarmData.id);  // Trigger refetch by changing the ID
        }
    };


    console.log("farm data", data)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [polygonCoordinatesUp, setPolygonCoordinatesUp] = useState([]);
    const [content, setContent] = useState('');
    const handlePolygonClick = (polygon) => {
        setContent(polygon);
    };

    const { dataFieldCoordinatesMulti, loadingCoor, errorCoor } = useFetchFieldCoordinatesMulti(token, selectedFarmId);
    console.log("dataFieldCoordinatesMulti", dataFieldCoordinatesMulti)

    useEffect(() => {
        console.log("only coors initial", dataFieldCoordinatesMulti);

        // Check if data is available and process it
        if (dataFieldCoordinatesMulti && dataFieldCoordinatesMulti.length > 0) {
            const filteredData = dataFieldCoordinatesMulti.filter(fieldData =>

                fieldData.farm_info === data[0].name

            );
            //console.log("data name ",data[0].name)
            console.log("Filtered Data:", filteredData);
            const cornersDataOld = filteredData.map(fieldData =>
                fieldData.coordinateInfo?.[0]?.corners ?? [],


            );

            const cornersData = filteredData.map(fieldData => ({
                coordinates: fieldData.coordinateInfo?.[0]?.corners ?? [],
                fieldInfo: fieldData.field_name // Capture the field_info here  
                //it was field_info to capture field id to show on map
            }));


            console.log("only coors corners", cornersData);

            if (cornersData.length > 0) {
                const newPolygonCoordinates = cornersData.map(polygonData => ({
                    coordinates: polygonData.coordinates.map(corner => {
                        const key = Object.keys(corner)[0];
                        return corner[key];
                    }),
                    fieldInfo: polygonData.fieldInfo
                }));
                setPolygonCoordinatesUp(newPolygonCoordinates);
                console.log("polygonCoordinatesUp", newPolygonCoordinates)
            }
        }
    }, [dataFieldCoordinatesMulti]);
    const containerStyle = {
        // width: '70vw',
        width: isMobile ? 'auto' : '70vw',
        height: isMobile ? '30vh' : '60vh'
        //width: 'auto',
        //height: '100vh'
        //height: isMobile ? '30vh' : '60vh',
    };

    const solarDevices = [
        {
            id: 1,
            todaysEnergy: '5 kWh',
            power: '3 kW',
            battery: '80%',
            totalEnergy: '50 kWh',
            timestamp: '12/03/2024'
        },
        {
            id: 2,
            todaysEnergy: '6 kWh',
            power: '3.5 kW',
            battery: '85%',
            totalEnergy: '60 kWh',
            timestamp: '12/03/2024'
        },
        {
            id: 3,
            todaysEnergy: '6 kWh',
            power: '3.5 kW',
            battery: '85%',
            totalEnergy: '60 kWh',
            timestamp: '12/03/2024'
        },
        // Add more devices as needed
    ];

    const { t } = useTranslation();

    const navigate = useNavigate();
    const navigateToSatView = (id) => {
        navigate(`/home/sattview/${id}`);
    };

    const [currentTime, setCurrentTime] = useState(new Date());
    /*
      useEffect(() => {
        // Update the time every second
        const timer = setInterval(() => {
          setCurrentTime(new Date());
        }, 1000); 
    
        // Clean up the timer when the component unmounts
        return () => clearInterval(timer);
      }, []); */

    // Format the date and time in 'DD/MM/YYYY HH:MM:SS' format
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };



    const handleSatelliteClick = () => {
        // Find the object where field_name matches the content
        const selectedField = dataFieldCoordinatesMulti.find(item => item.field_name === content);

        if (selectedField) {
            const fieldInfo = selectedField.field_info; // Get field_info

            // Navigate to the appropriate route
            navigate(`/home/sattview/${fieldInfo}`);
        } else {
            console.error('Field not found for content:', content);
        }
    };


    const { deviceData, loadingDevice, errorDevice } = useDeviceData(token);
    console.log("device data", deviceData)



    const filteredData = dataFieldCoordinatesMulti && deviceData ? dataFieldCoordinatesMulti
        .filter(field => field.farm_info === selectedFarm)  // Filter fields by the selected farm
        .map(field => {
            const devicesForField = deviceData.filter(device => device.field_info === field.field_info);

            return {
                farm_info: field.farm_info,
                field_info: field.field_info,
                field_name: field.field_name,
                devices: devicesForField
            };
        }) : [];

    const [buttonColor, setButtonColor] = useState('grey');  // Initial color is grey (inactive)
    const [isActive, setIsActive] = useState(false);  // Tracks if the button is currently active (green) or inactive (grey)
    const [isLoading, setIsLoading] = useState(false);  // Tracks if the button is currently active (green) or inactive (grey)

    const handleStartButtom = () => {
        // Step 1: Set loading state (orange)
        setButtonColor('orange');
        setIsLoading(true);  // Set loading to true to show the spinner

        // Step 2: After 2 seconds, toggle between active (green) and inactive (grey)
        setTimeout(() => {
            setIsLoading(false);  // Stop showing the spinner after 2 seconds
            if (isActive) {
                // If currently active, change to inactive (grey)
                setButtonColor('grey');
            } else {
                // If currently inactive, change to active (green)
                setButtonColor('green');
            }
            // Toggle the active/inactive state
            setIsActive(!isActive);
        }, 2000);  // 2 seconds
    };






    const { authData, setAuth } = useAuth();
    const [dataFarm, setDataFarm] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [errorData, setErrorData] = useState(false);
    const [deviceDataNew, setDeviceDataNew] = useState(null);

    useEffect(() => {
        async function fetchData() {
            setLoadingData(true);
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });

                const response = await fetch(`https://nabuapplication.com/api/farms/`, { headers });
                const data = await response.json();
                setDataFarm(data);
            } catch (e) {
                setErrorData(e);
            } finally {
                setLoadingData(false);
            }
        }

        if (token) {
            fetchData();
        }
    }, [token]);

    useEffect(() => {
        async function fetchData() {
            setLoadingData(true);
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });

                const response = await fetch(`https://nabuapplication.com/api/devices/`, { headers });
                const data = await response.json();
                console.log("fethed data gere:", data)
                setDeviceDataNew(data);
            } catch (e) {
                setErrorData(e);
            } finally {
                setLoadingData(false);
            }
        }

        if (token) {
            fetchData();
        }
    }, [token]);

    console.log("devices here:", deviceDataNew)
    console.log("authdata", authData.user.id)
    const user_id_value = authData.user.id

    //const { idWeather } = useParams();
    const idWeather = "14"

    const { center, dataWeather, loadingWeather, errorWeather } = useWeatherStationData(idWeather, token); // Use the custom hook
    console.log("dethed weather data here:", dataWeather)
    const [fields, setFields] = useState(null);


    useEffect(() => {
        if (dataWeather) {
            setFields(dataWeather);
        }
    }, [dataWeather])


    console.log("tempdta here:", dataWeather)
    const cleanData = fields
        ? fields.lora_weather_data
            .filter(item => item.atmospheric_pressure !== 6553.5) // Filter out specific atmospheric pressure readings
            .map(item => ({
                ...item,
                wind_speed: item.wind_speed * 3.6  // Convert wind speed from m/s to km/h
            }))
        : [];

    console.log("cleanData:", cleanData);

    const aggregateRainfallByDay = (dataWeather) => {
        const sumsByDay = {}; // Object to hold sum of rain per day
        dataWeather.forEach(item => {
            const date = new Date(item.timestamp * 1000); // Assuming timestamp is in seconds
            const day = date.toISOString().split('T')[0]; // Extract date as YYYY-MM-DD

            if (sumsByDay[day]) {
                sumsByDay[day] += item.rain; // Accumulate rainfall for the day
            } else {
                sumsByDay[day] = item.rain; // Initialize rainfall for the day
            }
        });

        return sumsByDay;
    }

    const tempData = cleanData.map((item) => item.atmospheric_temperature).reverse();
    const humidityData = cleanData.map(item => item.atmospheric_humidity).reverse();
    const wind_speedData = cleanData.map(item => item.wind_speed).reverse();
    const rainData = cleanData.map(item => item.rain).reverse();
    const accrainData = cleanData.map(item => item.leaf_wetness).reverse();
    const uvData = cleanData.map(item => item.solar_radiation).reverse();
    const lightData = cleanData.map(item => item.leaf_temperature).reverse();
    const pressureData = cleanData.map(item => item.atmospheric_pressure).reverse();
    const windirectionData = cleanData.map(item => item.wind_direction).reverse();
    //const tempData = dataFetch ? dataFetch.map((item) => item.temp).reverse() : [];
    const timeData = cleanData.map((item) => item.timestamp).reverse();

    const dailyRainfallTotals = aggregateRainfallByDay(cleanData);
    console.log("Daily Rainfall Totals:", dailyRainfallTotals);

    const dateStrings = timeData.map(timestamp => {
        // create a Date object for the time stamp
        const date = new Date(timestamp * 1000);

        // Create a custom date string without hours, month, and year
        const customDateString = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear() % 100}`;

        //return customDateString;

        // get a string representation of the date
        return date.toLocaleString();
    });

    const [sendData, setSendData] = useState(tempData.slice(0, 10));
    const [sendHumData, setSendHumData] = useState(humidityData.slice(0, 10));
    const [sendWspeedData, setSendWspeedData] = useState(wind_speedData.slice(0, 10));
    const [sendRainData, setSendRainData] = useState(rainData.slice(0, 10));
    const [sendAccRainData, setSendAccRainData] = useState(accrainData.slice(0, 10));
    const [sendUvData, setSendUvData] = useState(uvData.slice(0, 10));
    const [sendLightData, setSendLightData] = useState(lightData.slice(0, 10));
    const [sendPressureData, setSendPressureData] = useState(pressureData.slice(0, 10));
    const [sendWindDirData, setSendWindDirData] = useState(windirectionData.slice(0, 10));
    //const [timeDataView, setTimeDataView] =useState(dateStrings.slice(0, 10))
    const [timeDataView, setTimeDataView] = useState(dateStrings.slice(0, 10));

    useEffect(() => {
        setSendData(tempData.slice(0, 16).reverse());
        //setSendData(tempData.slice(-16));
        setTimeDataView(dateStrings.slice(0, 16).reverse());
        //setTimeDataView(dateStrings.slice(-16));
        setSendHumData(humidityData.slice(0, 16).reverse());
        setSendWspeedData(wind_speedData.slice(0, 16).reverse());
        setSendRainData(rainData.slice(0, 16).reverse());
        setSendAccRainData(accrainData.slice(0, 16).reverse());
        setSendUvData(uvData.slice(0, 16).reverse());
        setSendLightData(lightData.slice(0, 16).reverse());
        setSendPressureData(pressureData.slice(0, 16).reverse());
        setSendWindDirData(windirectionData.slice(0, 16).reverse());
    }, [fields])

    const [typeSelection, setTypeSelection] = React.useState('10'); // Default to 10 create a problem check it out later on

    useEffect(() => {
        const sliceSizes = {
            10: 48,
            20: 96,
            30: 336,
            40: 672,
        };

        if (sliceSizes.hasOwnProperty(typeSelection)) {
            const size = sliceSizes[typeSelection];
            setSendData(tempData.slice(0, size).reverse());
            setTimeDataView(dateStrings.slice(0, size).reverse());
            setSendHumData(humidityData.slice(0, size).reverse());
            setSendWspeedData(wind_speedData.slice(0, size).reverse());
            setSendRainData(rainData.slice(0, size).reverse());
            setSendAccRainData(accrainData.slice(0, size).reverse());
            setSendUvData(uvData.slice(0, size).reverse());
            setSendLightData(lightData.slice(0, size).reverse());
            setSendPressureData(pressureData.slice(0, size).reverse());
            setSendWindDirData(windirectionData.slice(0, size).reverse());
        } else {
            // Handle the default case here if needed
        }
    }, [typeSelection]);


    function createChartData(label, data, backgroundColor, borderColor) {
        return {
            labels: timeDataView,
            //labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
            datasets: [
                {
                    label,
                    data,
                    fill: true,
                    backgroundColor,
                    borderColor,
                },
            ],
        };
    }

    const dataX = createChartData("Temperature ( °C )", sendData, "rgba(255, 106, 106,0.2)", "rgba(255, 106, 106,1)");
    const Humidity = createChartData("Humidity ( % )", sendHumData, "rgba(67, 0, 1,0.2)", "rgba(67, 0, 1,1)");
    //const WindSpeed = createChartData("Wind Speed ( km/h )", sendWspeedData, "rgba(255, 218, 123,0.2)", "rgba(255, 218, 123,1)");
    const WindSpeed = createChartData(
        "Wind Speed (km/h)",
        sendWspeedData, // Convert speed to km/h, it converted earlier now to fix download data versioning
        "rgba(255, 218, 123, 0.2)",
        "rgba(255, 218, 123, 1)"
    );
    const WindDirection = createChartData("Wind Direction", sendWindDirData, "rgba(194, 124, 160, 0.2)", "rgba(194, 124, 160, 1)");
    const Rain = createChartData("Rain", sendRainData, "rgba(46, 232, 110,0.2)", "rgba(46, 232, 110,1)");
    const AccRain = createChartData("Leaf Wetness", sendAccRainData, "rgba(46, 232, 110,0.2)", "rgba(46, 232, 110,1)");
    const UV = createChartData("Solar Radiation ( W/m2 )", sendUvData, "rgba(22, 71, 39,0.2)", "rgba(22, 71, 39,1)");
    const Light = createChartData("Leaf Temperature ( °C )", sendLightData, "rgba(213, 206, 226, 0.2)", "rgba(213, 206, 226, 1)");
    const Pressure = createChartData("Pressure ( mbar )", sendPressureData, "rgba(75, 3, 213, 0.2)", "rgba(75, 3, 213, 1)");

    function angleToDirection(angle) {
        const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
        const index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8;
        return directions[index];
    }

    const downloadExcel = (loraWeatherData = []) => {
        if (!Array.isArray(loraWeatherData) || loraWeatherData.length === 0) {
            console.error('No data available or data is not an array.');
            return;
        }

        console.log("Download triggered");
        const formattedData = loraWeatherData.map(item => ({
            ...item,
            timestamp: new Date(item.timestamp * 1000).toLocaleString()
        }));

        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Weather Data");

        XLSX.writeFile(workbook, 'WeatherData.xlsx');
    };
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.35),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.45),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        marginBottom: 5,
        marginTop: "10px",
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    }));

    return (
        <div className='sensingPageMainLayout'>
            <div className='sensingPageLeftLayout'>
                <FormControl fullWidth>
                    <InputLabel >Select Device</InputLabel>
                    <Select
                        value={selectedFarm} // Adjust if needed to match your device selection state
                        onChange={handleChange} // Adjust to handle device selection
                        style={{ textAlign: 'left', fontWeight: 'bold' }}
                        displayEmpty
                        label="Select Your Device"
                        fullWidth={false}
                        sx={{
                            height: '40px', // Set the desired height for the Select component
                            fontSize: '14px', // Adjust font size if needed
                            '& .MuiSelect-select': {
                                padding: '8px', // Adjust padding for better alignment
                                fontWeight: 'bold', // Bold style for selected farm name
                            },
                        }}
                    >
                        {deviceDataNew && deviceDataNew.map((device) => (
                            <MenuItem
                                key={device.id}
                                value={device.name}
                                style={{ fontWeight: 'bold', textAlign: 'left' }}
                            >
                                {device.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {deviceDataNew ? (
                    <MapWS onPolygonClick={handlePolygonClick} deviceDataNew={deviceDataNew} />
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </div>
                )}
                {content === 'soil_moisture_X' ? (<div>Soil Moisture</div>) : (
                    <div className='sensingGraphsUp'>
                        <div className='chartWSinnerNew'>
                            <Line data={dataX} style={{}} />
                            <Line data={WindSpeed} />

                            <Line data={Light} />
                            <Line data={AccRain} />
                            <div className='responsive-chart-containerNew ' style={{ maxHeight: '300px' }}>
                                <TestChart data={dailyRainfallTotals} />

                            </div>

                        </div>
                        <div className='chartWSinnerNew'>
                            <Line data={Humidity} />
                            <Line data={WindDirection} />
                            <Line data={UV} />
                            <Line data={Pressure} />
                        </div>
                        <Search>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">  Available Data Duration</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={typeSelection}
                                    label="Select the Available Data Duration"
                                    onChange={evt => setTypeSelection(evt.target.value)}
                                    sx={{
                                        height: '40px', // Set the desired height for the Select component
                                        fontSize: '12px', // Adjust font size if needed
                                        '& .MuiSelect-select': {
                                            padding: '8px', // Adjust padding for better alignment
                                            fontWeight: 'bold', // Bold style for selected farm name
                                        },
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>

                                    </MenuItem>
                                    <MenuItem value={10}>Last 24 Hours</MenuItem>
                                    <MenuItem value={20}>Last 2 Day </MenuItem>
                                    <MenuItem value={30}>Last 1 Week</MenuItem>
                                    <MenuItem value={40}>Last 2 Week</MenuItem>

                                </Select>
                            </FormControl>


                        </Search>
                        <div className="downloadButtonNew">
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" onClick={() => downloadExcel(cleanData)} startIcon={<DownloadingIcon />}>Download as Excel
                                </Button>

                            </Stack>

                        </div>
                    </div>
                )}
                <div></div>
            </div>
            <div className='sensingPageRightLayout'>
                <div></div>
                <div className='sensingStat'></div>
                <div className='sensingPageNotificationCustom'>
                    <div className='homePageNotTitlePos'>
                        <div></div>
                        <div className='homePageNotTitle' style={{
                            backgroundColor: '#A6CE39',
                            color: 'white',
                            padding: '4px 30px',
                            fontFamily: 'Nunito Sans',
                            fontSize: '20px',
                            textAlign: 'center',
                            borderRadius: '8px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
                        }}>Notifications</div>
                        <div></div></div>
                    <div></div>
                </div>
            </div>

        </div>

    );
};



export default SensingPageMain;
