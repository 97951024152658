import Spline from '@splinetool/react-spline';

export default function Test() {
  return (
    <main>
      <Spline
        scene="https://prod.spline.design/mLNwHJOa3BDMdvgf/scene.splinecode" 
      />
    </main>
  );
}
