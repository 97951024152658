import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Helper function to format the date
function formatDate(dateString) {
    const date = new Date(dateString);
    const dayOfMonth = date.getDate();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return `${dayOfMonth}-${days[date.getDay()]}`; // Formats to "day-monthName"
}

// Transform the data into the desired format
function formatRainfallData(data) {
    return Object.keys(data).map(date => ({
        date: formatDate(date), // Format date
        Rainfall: data[date],   // Use rainfall amount
    }));
}

function TestChart({ data }) {
    const chartData = formatRainfallData(data);

    return (
        <div style={{ height: 300, width: '100%' }}>
            <ResponsiveContainer>
                <BarChart
                    data={chartData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        tick={{
                            fontSize: 12,
                            fill: '#666',
                            angle: -35,
                            textAnchor: 'end',
                        }}
                        height={60}
                    />
                    <YAxis
                        label={{
                            fontSize: 14,
                            value: 'Rainfall (mm)',
                            angle: -90,
                            position: 'insideLeft',
                        }}
                    />
                    <Tooltip />
                    <Bar dataKey="Rainfall" fill="#4682b4" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default TestChart;
