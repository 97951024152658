import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './sensing.css';
import { useTranslation } from 'react-i18next';
import moistureIconUrl from '../../assets/icon_SM.png'; 
import weatherStationIconUrl from '../../assets/icon1-weather.png';

const dotIcon = L.divIcon({
    className: 'gg-data',
    html: '<div class="dot-marker"></div>'
});

const soilIcon = L.divIcon({
    className: 'gg-edit-highlight',
    html: '<div class="dot-marker"></div>'
});

const moistureIcon = L.icon({
    iconUrl: moistureIconUrl, // Replace with the actual path to the moisture sensor icon
    iconSize: [25, 25], // Size of the icon
    iconAnchor: [12, 25], // Point of the icon which will correspond to marker's location
    popupAnchor: [0, -25], // Point from which the popup should open relative to the iconAnchor
    className: 'white-icon'
  });
  
  const weatherStationIcon = L.icon({
    iconUrl: weatherStationIconUrl, // Replace with the actual path to the weather station icon
    iconSize: [25, 25],
    iconAnchor: [12, 25],
    popupAnchor: [0, -25],
    className: 'white-icon'
  });

function MapWS({ onPolygonClick, deviceDataNew = [] }) {
    const { t } = useTranslation();

    console.log("Received device data from map element:", deviceDataNew);

    // Calculate center based on the average latitude and longitude of devices
    const center = deviceDataNew.length > 0 ? [
        deviceDataNew.reduce((sum, device) => sum + device.coordinates.lat, 0) / deviceDataNew.length,
        deviceDataNew.reduce((sum, device) => sum + device.coordinates.lng, 0) / deviceDataNew.length
    ] : [-13.56544093770141, 27.66495020067174];

    return (
        <MapContainer center={center} zoom={14} style={{ height: '100%', width: '100%', margin: '0px' }}>
            <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                attribution='&copy; <a href="https://www.esri.com">Esri</a> contributors'
            />

            {deviceDataNew.map((device, index) => (
                <Marker
                    key={index}
                    position={[device.coordinates.lat, device.coordinates.lng]}
                    icon={device.device_type === 'lora_weather_station' ? weatherStationIcon : moistureIcon}
                >
                    <Popup>
                        <div>
                            <strong>{t(device.device_type === 'lora_weather_station' ? 'Weather Station' : 'Soil Moisture')} {device.name}</strong>
                        </div>
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
}

export default MapWS;