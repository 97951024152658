import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './hooks/useAuth';
import Home from './pages/homePage/home';
import Auth from './pages/authPage/auth';
import './assets/languages/i18nConfig';
import './App.css';
import TestLayout from './layoutElements/testLayout';
import Satellite from './pages/satellitePage/satellite';
import Test from './components/test';
function ProtectedRoute({ children }) {
  const { user } = useAuth();
  
  return user ? children : <Navigate to="/" replace />;
}

function App() {
  const user = JSON.parse(localStorage.getItem('nabu-user'));
  return (
    <AuthProvider user = {user}>
      <Router>
          <Routes>
            <Route exact path ='/' element={<Auth></Auth>}></Route>   
            <Route exact path ='/home/*' element={<Home></Home>}></Route>
            <Route exact path ='/test/*' element={<TestLayout></TestLayout>}></Route>
            <Route exact path ='/satellite' element={<Satellite></Satellite>}></Route>
            <Route exact path ='/satelliteTest' element={<Satellite></Satellite>}></Route>
            <Route exact path ='/test' element={<Test></Test>}></Route>
        </Routes>
      </Router>
      </AuthProvider>
  );
}

export default App;