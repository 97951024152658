
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './satellite.css'

import GradientLine from './gradient';
import LineChartSat from './satLineChart';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useParams } from 'react-router-dom';
import { useToken } from '../../services/useToken';
import { useFetchFields } from '../../hooks/fetchSingeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import MyMapComponent from './mapElement';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import dayjs from 'dayjs';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Modal from './settingsSatImage';
import { fetchFieldsData } from './fetch_api_fields';
import NotificationsSatellite from './notificationSatellite';
import NotificationsPanel from '../homePageMain/notificationNew';
import '../../assets/fonts/fonts.css';
import LeftSection from './statusLeftSection';
import RightSection from './statusRightSection';
import Button from '@mui/material/Button';
import AccessTimeIcon from '@mui/icons-material/AccessTime';





const SatSectionLayout = () => {
    const { id } = useParams();
    const [selectedDataset, setSelectedDataset] = useState(null);
    const token = useToken()
    const [datasets, setDatasets] = useState([]);
    const [error, setError] = useState(null);

    const { dataField, loadin, erro } = useFetchFields(token, id);
    const [indexType, setIndexType] = useState('ndvi');
    const [value, setValue] = useState(null);
    const [datasetsDate, setDatasetsDate] = useState([]);
    let discardedDates = [];

    const [deviceData, setDeviceData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorFields, setErrorFields] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const data = await fetchFieldsData(token);
                setDeviceData(data);

            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
        }

        if (token) {
            fetchData();
        }
    }, [token]);

    const isDateAvailable = (date) => {
        // Create a new Date object to avoid mutating the original date
        let adjustedDate = new Date(date);

        // Subtract one day
        adjustedDate.setDate(adjustedDate.getDate() + 1);
        const formattedDate = adjustedDate.toISOString().split('T')[0];
        //return datasetsDate.includes(formattedDate);
        return datasetsDate.includes(formattedDate) && !discardedDates.includes(formattedDate);
    };

    const [view, setView] = React.useState('10');
    const handleChangeView = (event) => {
        setView(event.target.value);
    };

    const [isNormChecked, setIsNormChecked] = useState(false);
    const handleCheckboxNormChange = (event) => {
        const selectedValue = event.target.value;
        setIsNormChecked(selectedValue === 'Normalized');
    };



    const handleChangeIndex = (event) => {
        setIndexType(event.target.value);
    };

    useEffect(() => {
        //console.log("Date or Index Type changed");
        const formattedDate = value ? value.format('YYYY-MM-DD') : null;
        //console.log('Fetching data for date:', value, 'and index type:', indexType);

        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });

                let url = `https://nabuapplication.com/api/satelliteimage/${id}`;
                const params = new URLSearchParams();

                if (formattedDate) {
                    params.append('date', formattedDate);
                }
                if (indexType) {
                    params.append('index_type', indexType);
                }

                const response = await fetch(`${url}?${params.toString()}`, { headers });
                const data = await response.json();

                setSelectedDataset(data[0]); // Set the dataset based on the fetched data

                //console.log("Fetched data:", data);

            } catch (e) {
                setError(e);
            }
        }

        if (value || indexType) {
            fetchData();
        }
    }, [value, indexType]); // Depend on both value and indexType

    const handleDateChangePicker = (newValue) => {
        //console.log("Date picker value:", newValue);
        setValue(newValue);
    };

    const handleSelectionChange = (deviceId) => {
        navigate(`/home/sattview/${deviceId}`);
    };


    useEffect(() => {
        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });

                const response = await fetch(`https://nabuapplication.com/api/satelliteimage/${id}?index_type=ndvi`, { headers });
                const data = await response.json();

                // Once data is fetched, set the first dataset as the default selected dataset
                if (data.length > 0) {
                    setSelectedDataset(data[0]);


                }
                if (data.length > 0) {
                    setDatasets(data);
                    for (let i = 0; i < data.length; i++) {
                        // Check if the average is not 1
                        if (data[i].average !== 1) {
                            setSelectedDataset(data[i]);
                            return;  // Exit the function once the appropriate dataset is found
                        }
                    }
                    // this part done for temp because of eleminating broken data. in future it is better to drop them from backend or db side above loop checks if data broken which is avg is 1 and takes previos data to load on page
                    //setSelectedDataset(data[0]);  
                    //console.log("data:",data[1])
                }
            } catch (e) {
                setError(e);
            }
        }

        fetchData(); // This invokes the fetchData function.
    }, [id, token]);

    //console.log(selectedDataset)


    const [age, setAge] = React.useState('');
    const handleChange = (e) => {
        setAge(e.target.value);
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const { t } = useTranslation();


    const [dateCompare, setDateCompare] = useState(null);
    const [selectedDatasetDate, setSelectedDatasetDate] = useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });

                // Update the URL to match your backend endpoint
                const response = await fetch(`https://nabuapplication.com/api/satelliteimagedates/available_dates/?field_info_id=${id}`, { headers });
                const data = await response.json();

                // The dates are in the 'dates' key of the response
                if (data.dates && data.dates.length > 0) {
                    //console.log("dates heree")
                    //console.log(data.dates)
                    setDatasetsDate(data.dates);
                    //setSelectedDateInfo(data.dates[0]);
                } else {
                    // Handle the case where there are no dates
                    setDatasetsDate([]);
                    setSelectedDatasetDate(null);
                }
            } catch (e) {
                setError(e);
            }
        }

        fetchData(); // This invokes the fetchData function.
    }, [id, token]); // Dependencies for useEffect

    const [Etc, setEtc] = useState(null);

    useEffect(() => {
        if (value) {
            //console.log("valuechanged")
            const formattedDate = value.format('YYYY-MM-DD');
            async function fetchData() {
                try {
                    const headers = new Headers({
                        'Authorization': `Token ${token}`
                    });

                    const response = await fetch(`https://nabuapplication.com/api/etc/?field_info_id=${id}&date=${formattedDate}`, { headers });
                    const data = await response.json();

                    // Assuming the API returns the dataset for the selected date
                    setEtc(data[0]);    // this is the element that we are rendering !!!!!!!!!
                    //console.log("fetched etc")
                    //console.log(data)
                    //console.log("assigned etc")
                    //console.log(Etc)


                } catch (e) {
                    setError(e);
                }
            }

            fetchData();
        }
    }, [value, indexType, selectedDataset, token]);


    const [showModal, setShowModal] = useState(false);
    const handleSettings = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [currentTime, setCurrentTime] = useState(new Date());
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };

    // Set initial state to the first device ID if deviceData is available
    const [selectedDevice, setSelectedDevice] = useState('');

    // Use useEffect to set default value once deviceData is available
    useEffect(() => {
        if (deviceData && deviceData.length > 0) {
            setSelectedDevice(deviceData[0].id); // Default to the first device ID
        }
    }, [deviceData]);

    const handleChangeTitle = (event) => {
        const selectedId = event.target.value;
        setSelectedDevice(selectedId);
        handleSelectionChange(selectedId);
    };

    const [selectedDatasetCompare, setSelectedDatasetCompare] = useState(null);

    const [indexTypeCompare, setIndexTypeCompare] = useState('ndvi');



    const handleChangeIndexCompare = (event) => {
        setIndexTypeCompare(event.target.value);
    };

    const handleDateChangePickerCompare = (newDateCompare) => {
        //console.log("Date picker value:", newDateCompare);
        setDateCompare(newDateCompare);
    };
    const [selectedDatasetCompare2, setSelectedDatasetCompare2] = useState(null);

    const [indexTypeCompare2, setIndexTypeCompare2] = useState('ndvi');

    const [dateCompare2, setDateCompare2] = useState(null);

    const handleChangeIndexCompare2 = (event) => {
        setIndexTypeCompare2(event.target.value);
    };

    const handleDateChangePickerCompare2 = (newDateCompare) => {
        //console.log("Date picker value:", newDateCompare);
        setDateCompare2(newDateCompare);
    };

    useEffect(() => {
        //console.log("Date or Index Type changed");
        const formattedDate = dateCompare2 ? dateCompare2.format('YYYY-MM-DD') : null;
        //console.log('Fetching data for date:', dateCompare2, 'and index type:', indexTypeCompare2);

        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });

                let url = `https://nabuapplication.com/api/satelliteimage/${id}`;
                const params = new URLSearchParams();

                if (formattedDate) {
                    params.append('date', formattedDate);
                }
                if (indexTypeCompare2) {
                    params.append('index_type', indexTypeCompare2);
                }

                const response = await fetch(`${url}?${params.toString()}`, { headers });
                const data = await response.json();

                setSelectedDatasetCompare2(data[0]); // Set the dataset based on the fetched data
                //console.log("Fetched data:", data);

            } catch (e) {
                setError(e);
            }
        }

        if (dateCompare2 || indexTypeCompare2) {
            fetchData();
        }
    }, [dateCompare2, indexTypeCompare2]); // Depend on both value and indexType
    useEffect(() => {
        //console.log("Date or Index Type changed");
        const formattedDate = dateCompare ? dateCompare.format('YYYY-MM-DD') : null;
        //console.log('Fetching data for date:', dateCompare, 'and index type:', indexTypeCompare);

        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });

                let url = `https://nabuapplication.com/api/satelliteimage/${id}`;
                const params = new URLSearchParams();

                if (formattedDate) {
                    params.append('date', formattedDate);
                }
                if (indexTypeCompare) {
                    params.append('index_type', indexTypeCompare);
                }

                const response = await fetch(`${url}?${params.toString()}`, { headers });
                const data = await response.json();

                setSelectedDatasetCompare(data[0]); // Set the dataset based on the fetched data
                //console.log("Fetched data:", data);

            } catch (e) {
                setError(e);
            }
        }

        if (dateCompare || indexTypeCompare) {
            fetchData();
        }
    }, [dateCompare, indexTypeCompare]); // Depend on both value and indexType



    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <div className='satPageMainLayout'>
            <div className='satPageLeftLayout'>
                <div>        <FormControl fullWidth
                    sx={{
                        //height: '60px',
                        width: '100%' // Set the desired height
                    }}>
                    <InputLabel sx={{
                        fontSize: '0.8rem', // Adjust font size if needed
                        lineHeight: '1.2rem', // Adjust line height for better alignment
                    }}>{t("Select Your Field")}</InputLabel>
                    <Select
                        value={selectedDevice}
                        onChange={handleChangeTitle}
                        label={t("Select Your Field")}
                        sx={{
                            height: '40px', // Set the desired height for the Select component
                            fontSize: '14px', // Adjust font size if needed
                            '& .MuiSelect-select': {
                                padding: '8px', // Adjust padding for better alignment
                                fontWeight: 'bold', // Bold style for selected farm name
                            },
                        }}
                    >
                        {deviceData?.map(device => (
                            <MenuItem key={device.id} value={device.id}>
                                {device.name} {t("Satellite Data")}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl></div>
                <div><div className='satPageLeftMainUpNew'>
                    <div className='sattImaDropDown'>
                        <div></div>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select View</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={view}
                                label="Select View"
                                onChange={handleChangeView}
                            >
                                <MenuItem value={'10'}>Single View</MenuItem>
                                <MenuItem value={'20'}>Compare View</MenuItem>
                            </Select>
                        </FormControl>
                        <div></div>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Index</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={indexType}
                                label="Select View"
                                onChange={handleChangeIndex}
                            >
                                <MenuItem value={'ndvi'}>NDVI</MenuItem>
                                <MenuItem value={'ndre'}>NDRE</MenuItem>
                                <MenuItem value={'ndmi'}>NDMI</MenuItem>
                            </Select>
                        </FormControl>
                        <div></div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {/*<DatePicker
                        label="Controlled picker"
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    /> */}
                            <DatePicker
                                label="Select a Date"
                                value={value}
                                onChange={handleDateChangePicker}
                                renderInput={(params) => <TextField {...params} />}
                                shouldDisableDate={(date) => !isDateAvailable(date)}
                            />
                            {/*onChange={(newValue) => {
                            setValue(newValue);
                            handleDateChangePicker
                            
                            const formattedDate = newValue.format('YYYY-MM-DD');
                            console.log(formattedDate)
                            const dataset = datasets.find(d => d.date === formattedDate);
                            setSelectedDataset(dataset);
                        }}
                    */}
                        </LocalizationProvider>
                        <div></div>
                        <FormGroup
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',  // Centers horizontally
                                alignItems: 'center',      // Centers vertically (if needed)
                                marginLeft: '2px',         // Optional: keep this if you want slight left margin
                                width: '100%',             // Ensure the FormGroup takes up the full width of the parent
                            }}
                        >

                            <FormControl fullWidth>
                                <InputLabel>Select Type</InputLabel>
                                <Select
                                    value={isNormChecked ? 'Normalized' : 'Basic'} // Determine the selected value based on the state
                                    onChange={handleCheckboxNormChange}
                                    label="Select Type"
                                >
                                    <MenuItem value="Basic">Basic</MenuItem>
                                    <MenuItem value="Normalized">Normalized</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>

                    </div>

                    <div className='mapViewBatuSat'>
                        {view === "10" ? (
                            selectedDataset ? (
                                <div>
                                    {selectedDataset.cloud_percent > 15 && (
                                        <div style={{ textAlign: 'center' }}>
                                            <div
                                                style={{
                                                    backgroundColor: '#fff3cd',
                                                    color: '#856404',
                                                    padding: '10px',
                                                    border: '2px solid #ffeeba',
                                                    borderRadius: '5px',
                                                    margin: '10px auto',
                                                    marginLeft: '20%',
                                                    marginRight: '20%',
                                                }}
                                            >
                                                Warning: Cloud percentage is high!
                                            </div>
                                        </div>
                                    )}
                                    <div className="mapControl">
                                        <MyMapComponent
                                            key={selectedDataset.image_data} // Replace with a unique field from your dataset
                                            bounds={selectedDataset.bounds}
                                            imageBase64={isNormChecked ? selectedDataset.image_data_norm : selectedDataset.image_data}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div>Loading...</div>
                            )
                        ) : null}
                        {view === '20' && <div className='sattImageLeaflatMapCompare'>
                            {selectedDataset ? (
                                /* Check if selectedDataset has image_data and bounds that are not default values */
                                selectedDataset.image_data !== 'default_value' &&
                                    Array.isArray(selectedDataset.bounds) &&
                                    selectedDataset.bounds.length === 2 &&
                                    selectedDataset.bounds[0] &&
                                    selectedDataset.bounds[1] ? (
                                    <div>



                                        <div className='sattImageLeaflatMap'>
                                            {/*<CompareView  />  */}


                                            {selectedDatasetCompare ? (
                                                <MyMapComponent
                                                    bounds={selectedDatasetCompare.bounds}
                                                    imageBase64={selectedDatasetCompare.image_data}
                                                />) : (<MyMapComponent
                                                    bounds={selectedDataset.bounds}
                                                    imageBase64={selectedDataset.image_data}
                                                />)}
                                            {/*  Upper Part, Here avoiding to show nothing at the begining of compare view */}
                                            <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                                                <div className=' compareView_selections'>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Select Index</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={indexTypeCompare}
                                                            label="Select View"
                                                            onChange={handleChangeIndexCompare}
                                                        >
                                                            <MenuItem value={'ndvi'}>NDVI</MenuItem>
                                                            <MenuItem value={'ndre'}>NDRE</MenuItem>
                                                            <MenuItem value={'ndmi'}>NDMI</MenuItem>
                                                        </Select>

                                                    </FormControl>
                                                </div>
                                                <div className='compareView_selectionsDate'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label="DD/MM/YYYY"
                                                            value={dateCompare}
                                                            onChange={handleDateChangePickerCompare}

                                                            renderInput={(params) => <TextField {...params} />}
                                                            shouldDisableDate={(date) => !isDateAvailable(date)}


                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </div>

                                            {/* Up to here can be combined back with comapreView element*/}

                                        </div>
                                    </div>
                                ) : (
                                    <p>There is no data recorded for this day or the dataset is incomplete.</p>
                                )
                            ) : (
                                <p>Please select a date from the dropdown.</p>
                            )}
                            <div></div>
                            {selectedDataset ? (
                                /* Check if selectedDataset has image_data and bounds that are not default values */
                                selectedDataset.image_data !== 'default_value' &&
                                    Array.isArray(selectedDataset.bounds) &&
                                    selectedDataset.bounds.length === 2 &&
                                    selectedDataset.bounds[0] &&
                                    selectedDataset.bounds[1] ? (
                                    <div className='sattImageLeaflatMap'>
                                        {/*<CompareView  />  */}


                                        {selectedDatasetCompare2 ? (
                                            <MyMapComponent
                                                bounds={selectedDatasetCompare2.bounds}
                                                imageBase64={selectedDatasetCompare2.image_data}
                                            />) : (<MyMapComponent
                                                bounds={selectedDataset.bounds}
                                                imageBase64={selectedDataset.image_data}
                                            />)}
                                        {/*  Upper Part, Here avoiding to show nothing at the begining of compare view */}
                                        <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                                            <div className=' compareView_selections'>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Select Index</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={indexTypeCompare2}
                                                        label="Select View"
                                                        onChange={handleChangeIndexCompare2}
                                                    >
                                                        <MenuItem value={'ndvi'}>NDVI</MenuItem>
                                                        <MenuItem value={'ndre'}>NDRE</MenuItem>
                                                        <MenuItem value={'ndmi'}>NDMI</MenuItem>
                                                    </Select>

                                                </FormControl>
                                            </div>
                                            <div className='compareView_selectionsDate'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="DD/MM/YYYY"
                                                        value={dateCompare2}
                                                        onChange={handleDateChangePickerCompare2}

                                                        renderInput={(params) => <TextField {...params} />}
                                                        shouldDisableDate={(date) => !isDateAvailable(date)}


                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>

                                        {/* Up to here can be combined back with comapreView element*/}

                                    </div>
                                ) : (
                                    <p>There is no data recorded for this day or the dataset is incomplete.</p>
                                )
                            ) : (
                                <p>Please select a date from the dropdown.</p>
                            )}
                            {/*You selected Twenty*/}</div>}
                    </div>
                    <GradientLine />
                    <div></div>
                </div></div>
                <div><div className='satellitePageLeftGraph'>
                    <Box sx={{ minWidth: 100, fontFamily: 'Open Sans, sans-serif', display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                        <FormControl
                            sx={{ width: '30%', fontFamily: 'Open Sans, sans-serif' }}  // Adjust width and center
                        >
                            <InputLabel id="demo-simple-select-label" sx={{ fontFamily: 'Open Sans, sans-serif' }}>{t("Select Curve")}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleChange}
                                sx={{ fontFamily: 'Open Sans, sans-serif' }}
                                className="customSelect"
                            >
                                <MenuItem value={10} sx={{ fontFamily: 'Open Sans, sans-serif' }}>{t("Actual Vegetation")}</MenuItem>
                                <MenuItem value={20} sx={{ fontFamily: 'Open Sans, sans-serif' }}>{t("Vegetation Analysis")}</MenuItem>
                                <MenuItem value={30} sx={{ fontFamily: 'Open Sans, sans-serif' }}>{t("Moisture Analysis")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <div className='lineChartWrapper'>
                        <LineChartSat></LineChartSat>
                    </div>
                </div></div>
            </div>

            <div className='satPageRightLayout'>
                <div className='iconWrap' onClick={handleSettings} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginRight: "3%" }}><SettingsOutlinedIcon />
                    {showModal && (
                        <Modal onClose={handleCloseModal}>
                            {/* Content of the modal, such as the settings form */}
                            {/* Add your settings form components here */}
                        </Modal>
                    )}
                </div>
                <div className='statusSat'>
                    <div className='statusSatUp'>
                        <div className='statusSatUpHorizontal'>
                            <div style={{ backgroundColor: '', display: 'flex', flexDirection: 'column', justifyContent: '', alignItems: '' }}>
                                {/* Crop Title */}
                                <div style={{ fontSize: '20px', fontFamily: 'Bebas Neue', textAlign: '', color: '#2082A4' }}>
                                    {t("Crop")}
                                </div>
                                {/* Crop Type Data */}
                                <div style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Nunito Sans', textAlign: '' }}>
                                    {dataField ? (dataField.crop_type ? dataField.crop_type : 'None') : ''}
                                </div>
                            </div>
                            <div style={{ backgroundColor: '', display: 'flex', flexDirection: 'column', justifyContent: '', alignItems: '' }}>
                                {/* Etc Title */}
                                <div style={{ fontSize: '20px', fontFamily: 'Bebas Neue', textAlign: '', color: '#2082A4' }}>
                                    {t("Etc")}
                                </div>

                                {/* Etc Data */}
                                <div style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Nunito Sans', textAlign: '' }}>
                                    {Etc ? (
                                        Etc.Etc === 99999 ? (
                                            <ReportProblemIcon
                                                style={{
                                                    color: 'orange',
                                                    paddingTop: '8px',
                                                    paddingLeft: '10px',
                                                    paddingBottom: '8px',
                                                    fontSize: '3.5vh',
                                                }}
                                            />
                                        ) : (
                                            <strong>
                                                <div className="dataCardDataElements">{Etc.Etc.toFixed(2)}mm</div>
                                            </strong>
                                        )
                                    ) : (
                                        <strong>
                                            <div className="dataCardDataElements">-</div>
                                        </strong>
                                    )}
                                </div>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '',
                                    display: 'flex',
                                    flexDirection: 'column', // Stack title and value vertically
                                    justifyContent: '',
                                    alignItems: '', // Center the content
                                }}
                            >
                                {/* Average Vegetation Title */}
                                <div
                                    className="dataCardTitle"
                                    style={{
                                        fontSize: '20px',
                                        fontFamily: 'Bebas Neue',
                                        textAlign: '', // Center the title
                                        marginTop: '2%',
                                        color: '#2082A4',
                                    }}
                                >
                                    {t("Average Vegetation")}
                                </div>
                                <div></div>
                                {/* Average Vegetation Data */}
                                <div
                                    className="dataCardValue"
                                    style={{
                                        fontSize: '22px',
                                        fontWeight: '700',
                                        fontFamily: 'Nunito Sans',
                                        textAlign: '', // Center the value
                                    }}
                                >
                                    {selectedDataset?.average !== undefined
                                        ? selectedDataset.average < 0
                                            ? "0%"
                                            : `${Math.round(selectedDataset.average * 100)}%`
                                        : "No Data"}
                                </div>
                            </div>
                        </div>
                        <div className='statusSatUpHorizontal'>
                            <div style={{ backgroundColor: '', display: 'flex', flexDirection: 'column', justifyContent: '', alignItems: '' }}>
                                {/* Min Title */}
                                <div
                                    className="dataCardTitle"
                                    style={{
                                        fontSize: '20px',
                                        fontFamily: 'Bebas Neue',
                                        textAlign: '',
                                        marginTop: '2%',
                                        color: '#2082A4',
                                    }}
                                >
                                    Min
                                </div>
                                <div></div>
                                {/* Min Data */}
                                <div
                                    className="dataCardValue"
                                    style={{
                                        fontSize: '22px',
                                        fontWeight: '700',
                                        fontFamily: 'Nunito Sans',
                                        textAlign: '',
                                    }}
                                >
                                    {selectedDataset?.minimum !== undefined
                                        ? selectedDataset.minimum < 0
                                            ? "0%"
                                            : `${Math.round(selectedDataset.minimum * 100)}%`
                                        : "No Data"}
                                </div>
                            </div>
                            <div style={{ backgroundColor: '', display: 'flex', flexDirection: 'column', justifyContent: '', alignItems: '' }}>
                                {/* Max Title */}
                                <div
                                    className="dataCardTitle"
                                    style={{
                                        fontSize: '20px',
                                        fontFamily: 'Bebas Neue',
                                        textAlign: '',
                                        marginTop: '2%',
                                        color: '#2082A4',
                                    }}
                                >
                                    Max
                                </div>
                                <div></div>
                                {/* Max Data */}
                                <div
                                    className="dataCardValue"
                                    style={{
                                        fontSize: '22px',
                                        fontWeight: '700',
                                        fontFamily: 'Nunito Sans',
                                        textAlign: '',
                                    }}
                                >
                                    {selectedDataset?.maximum !== undefined
                                        ? selectedDataset.maximum < 0
                                            ? "0%"
                                            : `${Math.round(selectedDataset.maximum * 100)}%`
                                        : "No Data"}
                                </div>
                            </div>
                            <div style={{ backgroundColor: '', display: 'flex', flexDirection: 'column', justifyContent: '', alignItems: '' }}>
                                {/* Median Vegetation Title */}
                                <div
                                    className="dataCardTitle"
                                    style={{
                                        fontSize: '20px',
                                        fontFamily: 'Bebas Neue',
                                        textAlign: '',
                                        marginTop: '2%',
                                        color: '#2082A4',
                                    }}
                                >
                                    {t("Median Vegetation")}
                                </div>
                                <div></div>
                                {/* Median Vegetation Data */}
                                <div
                                    className="dataCardValue"
                                    style={{
                                        fontSize: '22px',
                                        fontWeight: '700',
                                        fontFamily: 'Nunito Sans',
                                        textAlign: '',
                                    }}
                                >

                                    {selectedDataset?.median !== undefined ? selectedDataset.median < 0
                                        ? "0%"
                                        : `${Math.round(selectedDataset.median * 100)}%` : "No Data"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='irrigationSchSat'>
                        <div className='irrigationSchSatVertical'>
                            <div className='irrigationSchSatBut'>   <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    minWidth: '80px',
                                    height: '20px',
                                    fontSize: '16px',
                                    fontFamily: 'Bebas Neue, sans-serif',
                                    padding: '12px 4px',
                                    background: '#B7B7B7',
                                    borderRadius: '6px',
                                    opacity: 1,
                                    boxShadow: 'none', // Removes any default shadow if present
                                    '&:hover': {
                                        backgroundColor: '#9C9C9C', // Slightly darker hover effect
                                    },
                                }}
                            >
                                Skip
                            </Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        minWidth: '80px',
                                        height: '20px',
                                        fontSize: '16px',
                                        fontFamily: 'Bebas Neue, sans-serif',
                                        padding: '12px 4px',
                                        border: '1px solid #B7B7B7',
                                        borderRadius: '6px',
                                        opacity: 1,
                                        backgroundColor: 'transparent', // Ensure no background
                                        color: '#757575', // Match text color to the border
                                        '&:hover': {
                                            backgroundColor: 'rgba(117, 117, 117, 0.1)', // Slight hover effect (optional)
                                        },
                                    }}
                                >
                                    Start
                                </Button></div>
                            <div>    <div
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    marginBottom: '10px',
                                    color: '#333',
                                }}
                            >
                                Weekly Calendar
                                <span style={{ marginLeft: '5px', color: '#999', cursor: 'pointer' }}>ℹ️</span>
                            </div></div>
                            <div className='dateBoxes'>
                                <div className='dateBoxesDetail'>
                                    <div className='dateBoxesDetails'>S</div>
                                    <div className='dateBoxesDetailsOpt'>M</div>
                                    <div className='dateBoxesDetailsOpt'>T</div>
                                    <div className='dateBoxesDetails'>W</div>
                                    <div className='dateBoxesDetailsOpt'>T</div>
                                    <div className='dateBoxesDetailsOpt'>F</div>
                                    <div className='dateBoxesDetailsOpt'>S</div>

                                </div>
                                <div className='dateBoxesDetail'><div className='dateBoxesDetailsOpt'>S</div>
                                    <div className='dateBoxesDetails'>M</div>
                                    <div className='dateBoxesDetails'>T</div>
                                    <div className='dateBoxesDetailsOpt'>W</div>
                                    <div className='dateBoxesDetailsOpt'>T</div>
                                    <div className='dateBoxesDetailsOpt'>F</div>
                                    <div className='dateBoxesDetails'>S</div></div>
                            </div></div>
                        <div className='irrigationSchSatVertical'>
                            <div className='timeBox'>
                                <div></div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center', // Center horizontally
                                    alignItems: 'center', // Center vertically
                                    height: '100%', // Ensure the container has height
                                }}><AccessTimeIcon style={{ fontSize: '16px', color: 'gray', }} /></div>
                                <div>Time</div>
                                <div></div>
                                <div><select
                                    style={{
                                        padding: '0px',
                                        borderRadius: '1px',
                                        cursor: 'pointer',
                                        outline: 'none', // Removes the outline
                                        border: '1px solid #ccc', // Optional: Add a subtle border for better appearance
                                    }}
                                >
                                    <option>07:00</option>
                                    <option>08:00</option>
                                    <option>09:00</option>
                                </select></div>
                            </div>
                            <div><div
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    marginBottom: '10px',
                                    color: '#333',
                                }}
                            >
                                Shifts
                            </div></div>
                            <div className='shifts'>
                                <div className='shiftsDetail'>
                                    <div></div>
                                    <div>Name</div>
                                    <div>Amount</div>
                                    <div>Factor</div>
                                </div>
                                <div className='shiftsDetail'>
                                    <input
                                        type="checkbox"
                                        style={{
                                            width: '14px', // Adjust the size of the checkbox
                                            height: '14px',
                                            accentColor: 'gray', // Set the color of the checkbox to gray
                                            cursor: 'pointer', // Optional: add a pointer cursor
                                        }}
                                    />
                                    <div>Shift 1</div>
                                    <div>7mm</div>
                                    <div>100%</div>
                                </div>
                                <div className='shiftsDetail'>
                                    <input
                                        type="checkbox"
                                        style={{
                                            width: '14px', // Adjust the size of the checkbox
                                            height: '14px',
                                            accentColor: 'gray', // Set the color of the checkbox to gray
                                            cursor: 'pointer', // Optional: add a pointer cursor
                                        }}
                                    />
                                    <div>Shift 2</div>
                                    <div>8mm</div>
                                    <div>100%</div>
                                </div></div></div></div>
                </div>
                <div className='sattPageNotificationCustom'>
                    <div className='sattPageNotTitlePos'>
                        <div></div>
                        <div className='sattPageNotTitle' style={{
                            backgroundColor: '#A6CE39',
                            color: 'white',
                            padding: '4px 30px',
                            fontFamily: 'Nunito Sans',
                            fontSize: '20px',
                            textAlign: 'center',
                            borderRadius: '8px',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
                        }}>Notifications</div>
                        <div></div></div>
                    <div></div>
                </div></div>

        </div>
    );
};

export default SatSectionLayout;
